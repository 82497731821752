@use '../custom-variables/mixins';
@use '../custom-variables/grid';

.header-section {
  .main-header-section {
    @include mixins.header-bg-1('/img/bg/grids/grid-1.svg');

    .domain-input {
      max-width: 390px;
    }

    .domain-search-form-block {
      @media (max-width: grid.get-grid-bp('lg')) {
        margin-top: 2rem;
      }
    }

    .domain-check-label {
      display: flex;
      flex-direction: column;
      align-items: center;

      .gpt-toggler {
        font-size: 12px;
        font-weight: 400;
        text-decoration-line: underline;

        cursor: pointer;
      }
    }

    .header-right-section {
      &.with-person {
        .img-block {
          &::before {
            left: 4rem;
          }
        }
      }
    }
  }
}
